import React, { useState /*, useEffect, useContext */ } from 'react';

/*
import ReactPlayer from 'react-player';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faBookmark, faFileAudio, faUser,
	faPlayCircle
} from '@fortawesome/free-regular-svg-icons';
*/
import { /* Row, Col, Navbar, Nav, Tab, */ Modal /*, Toast, Image */ } from 'react-bootstrap';

import KuulaProject from './components/KuulaProject';

import './styles/App.css';
import './styles/TabStyles.css';

/*
var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

const audioPlaylist = [
	{ id: 1, title: "Lil Bitcoin ft. Crypto Chris - Bitcoin",	file_loc: "lil_bitcoin_ft_crypto_chris_-_Bitcoin.mp3" },
	{ id: 2, title: "Lil Bitcoin - I Love Bitcoin",				file_loc: "lil_bitcoin_-_i_love_bitcoin.mp3" },
	{ id: 3, title: "Crypto Chris - Winklevoss Boss",			file_loc: "crypto_chris_-_winklevoss_boss.mp3" },
	{ id: 4, title: "Lil Bitcoin - Bag Boy",					file_loc: "lil_bitcoin_-_bag_boy.mp3" }
];

const site_items = [
	
	{ id: 1, 	hotspot_name: "easter_egg_1", 	img_loc: "1-Statue-Head.png",  		"title": "Statue Head",				"description": "", "visible": false },
	{ id: 2, 	hotspot_name: "easter_egg_2", 	img_loc: "2-Bill-Skull.png",  		"title": "Bill Skull",				"description": "", "visible": false },
	{ id: 3, 	hotspot_name: "easter_egg_3", 	img_loc: "3-Car.png",  				"title": "Car",						"description": "", "visible": false },
	{ id: 4, 	hotspot_name: "easter_egg_4", 	img_loc: "4-CryptoFrame.png",  		"title": "Crypto Frame",			"description": "", "visible": false },
	{ id: 5, 	hotspot_name: "easter_egg_5", 	img_loc: "5-Plane.png",  			"title": "Plane",					"description": "", "visible": false },
	{ id: 6, 	hotspot_name: "easter_egg_6", 	img_loc: "6-F.png",  				"title": "F",						"description": "", "visible": false },
	{ id: 7, 	hotspot_name: "easter_egg_7", 	img_loc: "7-Speaker.png",  			"title": "Speaker",     			"description": "", "visible": false },
	{ id: 9, 	hotspot_name: "easter_egg_8",	img_loc: "8-Frame.png",  			"title": "Frame",     				"description": "", "visible": false },
	{ id: 9, 	hotspot_name: "easter_egg_9", 	img_loc: "9-Frame-2.png",			"title": "Frame 2",     			"description": "", "visible": false },
	{ id: 10, 	hotspot_name: "easter_egg_10", 	img_loc: "10-Goat.png", 			"title": "Goat",					"description": "", "visible": false },
	{ id: 11, 	hotspot_name: "easter_egg_11", 	img_loc: "11-Painting.png",  		"title": "Painting",				"description": "", "visible": false }
]
*/

export default function App() {

	// const { tour } = useTour();
	const [ show, setShow ] = useState(true);
	const [ firstLoad, setFirstLoad ] = useState(true);

	/*
	const [ toastList, setToastList ] = useState([]);

	const [ offcanvas, setOffcanvas ] = useState('show');
	const [ tabSelected, setTab ] = useState('audio');

	const [ audioList, setPlayList ] = useState(audioPlaylist);
	const [ selectedTrackID, setSelectedTrack ] = useState(1);
	const [ audio_playing, setAudioPlaying ] = useState(false);
	const [ chosenTrack, setChosenTrack ] = useState(audioPlaylist[0].file_loc);

	const [ items, setItems ] = useState([ ...site_items ]);
	const [ itemCount, setItemCount ] = useState(0);

	const [ hotspot, setSelectedHotspot ] = useState('');
	
	function toggleCanvas() {
		if ( offcanvas === '' )
			setOffcanvas('show')
		else 
			setOffcanvas('')
	}
	*/

	function handleClose() {
		setShow(false);
		if ( firstLoad ) {
			setFirstLoad(false);
			// setAudioPlaying(true);
		}
	}

	/*
	useEffect(() => {
		if ( localStorage.getItem('eggs') ) {
			let count = 0;
			var localEggs = JSON.parse(localStorage.getItem('eggs'));
			var updated_items = localEggs?.map(item => {
			if (item.visible) count++;
				return item;
			})
			setItemCount(count);
			setItems([ ...updated_items ])
		}

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

		KuulaPlayerAPI.addEventListener("hotspot", function(e) {
			console.log('hotspot data id', e.data.uid);
			console.log('hotspot data name', e.data.name);
			setSelectedHotspot(e.data.name);
		});
	
	}, []);

	useEffect(() => {
		if ( hotspot !== '' ) {
			// check if item clicked on
			let new_count = itemCount;
			
			console.log('hotspot', hotspot)

			var new_toast = {}
			var new_items = items.map(item => {
				if ( item.hotspot_name === hotspot && !item.visible ) {
					new_count++;
					// add item to toast list
					new_toast = {
					'id': item.id,
					'message': 'Hidden Item Found!',
					'item_name': item.title,
					'img': item.img_loc
					}
					return { ...item, visible: true }
				}
				return { ...item };  
			});

			if ( new_count > itemCount ) {

				setItemCount(new_count);
				setItems([ ...new_items ]);
				localStorage.setItem('eggs', JSON.stringify(new_items))
				setToastList([
					...toastList,
					new_toast
				])

			}
			// else {

			// 	// if not item, check if room navigation clicked on
			// 	var nav_found = nav_points.find(nav => nav.hotspot_name === hotspot);
			// 	if (nav_found) {
			// 		// console.log('room_found', room_found)
			// 		openTourScene(nav_found.kuula_scene_id)
			// 	}

			// }
		}

	}, [hotspot])

	function openTourScene(scene_id){
		var visitedCount = 0;
		var new_rooms = rooms.map(room => {
			if ( room.kuula_scene_id === scene_id ) {
				room.visited = true
			}
			if ( room.visited ) {
				visitedCount++;
			}
			return room;
		});

		setCurrentRoom(scene_id);
		setRooms(new_rooms);
		setRoomCount(visitedCount)

		KuulaPlayerAPI.load(frame_id, scene_id);
	}

	function showInstructions(e) {
		e.preventDefault();
		setShow(true);
	}

	function clearFoundItems() {
		localStorage.removeItem('eggs');
		localStorage.setItem('eggs', JSON.stringify([ ...site_items ]));
		setItems([ ...site_items ]);
		setItemCount(0);
		setSelectedHotspot('')
	}

	function removeToastFromList(toast_id) {
		var removeItemIndex = toastList.findIndex(toast => toast.id === toast_id);
		var newToastList = [ ...toastList ];
		newToastList.splice(removeItemIndex, 1);
		setToastList(newToastList);
	}

	function playAudio(audio_id) {
		setSelectedTrack(audio_id)
		var track_index = audioList.findIndex(audio => audio.id === audio_id);
		setChosenTrack(audioList[track_index].file_loc)
		setAudioPlaying(true)
	}
	
	function playPauseAudio() {
		setAudioPlaying(!audio_playing)
	}
	
	function playNextTrack() {
		var current_track_index = audioList.findIndex(audio => audio.id === selectedTrackID)
		if ( current_track_index < audioList.length ) {
			setSelectedTrack(audioList[current_track_index + 1].id)
			setChosenTrack(audioList[current_track_index + 1].file_loc)
			setAudioPlaying(true)
		}
	}
	*/

	return (
		<div className="App">

			<Modal show={show} onHide={handleClose}>
				<Modal.Body>
					<img src={`images/intro-instr.jpg`} alt="Instructions" style={{ width: '100%' }} />
				</Modal.Body>
				<Modal.Footer style={{ textAlign: 'center' }}>
					<button type="button" className="btn" style={{ backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', fontSize: '1.5em', border: 'solid 1px #000000' }} onClick={handleClose}>
						{firstLoad ? 'Enter!' : 'Close'}
					</button>
				</Modal.Footer>
			</Modal>
			{/*
			<Navbar variant="dark" bg="transparent" fixed="bottom" expand={false} defaultExpanded={true}>
				<Navbar.Toggle className="p-0 border-0" data-toggle="offcanvas" onClick={toggleCanvas} />
				<Navbar.Collapse id="offcanvas_navigation">
				
					<Tab.Container activeKey={tabSelected} onSelect={(k) => setTab(k)}>
						<Row id="tour-title-row">
							<Col>
								<img src={`images/cmr-museum.png`} alt="CMR Logo" style={{ width: '100px', float: 'right', borderRadius: '5px' }} className="mx-3" />
								<div style={{ maxWidth: '200px' }}>
									<p>
										<img src="images/menu-title.png" alt="Menu Title" style={{ height: '40px' }} />
									</p>
									[ <a href="#" onClick={(e) => showInstructions(e)} style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }}>Instructions</a> ]
								</div>
							</Col>
						</Row>
						<Row id="tab-row">
							<Col>
								<Nav justify variant="tabs" defaultActiveKey="chat" style={{ flexDirection: 'row', maxWidth: '400px' }}>
									<Nav.Item>
										<Nav.Link eventKey="chat">
											<FontAwesomeIcon icon={faUser} />
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="audio">
											<FontAwesomeIcon icon={faFileAudio} />
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="rooms">
											<FontAwesomeIcon icon={faFolder} /> <span id="rooms_visited">{roomCount}</span>/<span id="rooms_total">{rooms.length}</span>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="items">
											<FontAwesomeIcon icon={faBookmark} /> <span id="items_found">{itemCount}</span>/<span id="items_total">{items.length}</span>
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
						</Row>
						<Row style={{ margin: 0 }}>
							<Col>
								<Tab.Content>
									<Tab.Pane transition={false} eventKey="chat">
										<iframe title="discord_embed" src="https://discord.com/widget?id=874158254069010453&theme=dark" 
											width="350" height="500" allowtransparency="true" frameborder="0" 
											sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
									</Tab.Pane>
								
									<Tab.Pane transition={false} eventKey="audio">
										<div id="audio_player">
											<ReactPlayer
												className='react-player'
												url={'audio/' +chosenTrack}
												width='100%'
												height='100%'
												playing={audio_playing}
												controls={true}
												onEnded={playNextTrack}
												style={{ height: 'revert' }}
												// Disable download button
												config={{ file: { attributes: { controlsList: 'nodownload' } } }}
												// Disable right click
												onContextMenu={e => e.preventDefault()}
											/>
										</div>
										<ol id="audio_list" className="navbar-nav mr-auto">
											{audioList?.map(audio => {
												return (
													<li key={audio.id} className={'nav-item'}>
														<a id={`audio_${audio.id}`} className={'nav-link' + (audio.id === selectedTrackID ? ' active' : '')} onClick={(e) => playAudio(audio.id)}>
															{audio.id === selectedTrackID && (
																<FontAwesomeIcon icon={faPlayCircle} className="mr-2" />
															)}
															{audio.title}
														</a>
													</li>
												)
											})}
										</ol>
									</Tab.Pane>

									<Tab.Pane transition={false} eventKey="rooms">
										<ul id="rooms" className="navbar-nav mr-auto pl-3">
											{rooms?.map(room => {
												return (
													<>
													{room.id === 7 && (
														<hr style={{ display: 'block', height: '1px',
															border: '0', borderTop: '1px solid #ccc',
															margin: '0 0', padding: '0' }} />
													)}
													<li key={room.id} id={`menu_${room.kuula_scene_id}`} className={'nav-item' + (room.visited ? ' visited' : '')}>
														{room.status === "inactive" ? (
															<a id={`room_${room.kuula_scene_id}`} className={'nav-link disabled'} >
																{room.title}
															</a>
														) : (
															<a id={`room_${room.kuula_scene_id}`} className={'nav-link' + (room.kuula_scene_id === current_room ? ' active' : '')} onClick={(e) => openTourScene(room.kuula_scene_id)}>
																{room.visited && (
																	<FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
																)}
																{room.title}
																{room.section === 2 && (
																	<span className="ml-2" style={{ fontSize: '.8em' }}>( New )</span>
																)}
															</a>
														)}
													</li>
													</>
												)
											})}
										</ul>
									</Tab.Pane>

									<Tab.Pane transition={false} eventKey="items">
										{items.length && (
											<div className="tab-pane-instr">
												Can you find all the Hidden Items?<br /> Clicking the items in the 360 viewer will reveal them here. Hover over the item in the menu for a Hint
												{itemCount > 0 && (
													<div>
														[ <a style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }} onClick={() => { clearFoundItems() }}>Clear Found Items</a> ]
													</div>
												)}
											</div>
										)}
										<div id="items">
											<Row>
												{items?.map((item, idx) => 
													item.visible ? (
														<Col xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/items/${item.img_loc}`} alt="found item" className="item_thumb" title={item.title} fluid />
														</Col>
													) : (
														<Col xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/item_hidden.png`} alt="hidden item" className="item_thumb" title={'Hint: ' +item.title} fluid />
														</Col>
													)
												)}
											</Row>
										</div>
									</Tab.Pane>

								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Navbar.Collapse>
			</Navbar>
			

			<div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000' }}>
				{toastList?.map(toast => {
					return (
						<Toast key={toast.id} onClose={() => removeToastFromList(toast.id)} show={true} delay={7000} autohide>
							<Toast.Header>
								<FontAwesomeIcon icon={faBookmark} className="mr-3" />
								<strong className="mr-auto">{toast.message}</strong>
							</Toast.Header>
							<Toast.Body style={{ textAlign: 'left' }}>
								<img src={'images/items/' +toast.img} className="rounded mr-2" style={{ height: '50px', width: '50px' }} alt="" />
								{toast.item_name}
							</Toast.Body>
						</Toast>
					)
				})}
			</div>
			*/}

			<KuulaProject />
		
		</div>
	)
}